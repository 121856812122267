.btn-restart {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .btn-restart:hover {
    background-color: #45a049;
  }

  .btn-stop {
    position: fixed; /* Position fixe pour rester en bas de l'écran */
    bottom: 80px; /* Espace de 20px depuis le bas de l'écran */
    left: 50%; /* Centré horizontalement */
    transform: translateX(-50%); /* Correction pour le centrer exactement */
    background-color: #f44336; /* Couleur de fond rouge pour la visibilité */
    color: white; /* Couleur de texte blanche */
    border: none; /* Sans bordure */
    padding: 10px 20px; /* Espacement interne */
    font-size: 16px; /* Taille du texte */
    cursor: pointer; /* Curseur en forme de main pour indiquer la cliquabilité */
    z-index: 1000; /* Assure que le bouton reste au-dessus de tout autre contenu */
  }
  
  .btn-stop:hover {
    background-color: #d32f2f; /* Changer de couleur lors du survol */
  }

  .highlight {
    border: 2px solid #ff0; /* Couleur jaune vif pour le surlignage */
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.8); /* Ombre jaune autour */
    border-radius: 5px;
  }