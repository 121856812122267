/* GeolocationModal.css */

.geolocation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .geolocation-modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .geolocation-modal-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .geolocation-modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .geolocation-modal-content input:focus {
    border-color: #007bff;
  }
  
  .suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-top: -10px; /* Ajuste la marge pour coller à l'input */
  }
  
  .suggestions-list li {
    border-bottom: 1px solid #eee;
  }
  
  .suggestions-list li:last-child {
    border-bottom: none;
  }
  
  .suggestion-button {
    width: 100%;
    padding: 10px;
    text-align: left;
    background-color: #fff;
    border: none;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .suggestion-button:hover {
    background-color: #f0f0f0;
  }
  
  .geolocation-modal input[type="text"]:focus {
    border-color: #007bff; /* Couleur de la bordure lors de la mise au point */
  }
  
  .geolocation-modal-buttons button {
    background-color: #007bff; /* Couleur bleue pour les boutons */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s, transform 0.3s;
    width: calc(100% - 20px);
  }