/* src/components/ProfileForm.css */

.profile-form {
  max-width: 600px;
  margin: 40px auto; /* Centrer et espacer davantage le formulaire */
  padding: 30px;
  background: #f9f9f9; /* Fond légèrement gris pour un look moderne */
  border: none;
  border-radius: 12px; /* Bordures plus arrondies */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Ajout d'une ombre douce pour l'effet de carte */
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

.profile-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Ajustez la taille de la police selon vos préférences */
  color: #2F732F; /* Couleur verte pour un look attrayant */
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center; /* Centre le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  margin-top: 20px;
}

.profile-form h2 {
  margin-bottom: 30px;
  font-size: 1.8em;
  color: #343a40; /* Couleur de titre plus sombre */
  text-align: center;
  border-bottom: 2px solid #007bff; /* Ligne sous le titre pour le séparer du contenu */
  padding-bottom: 10px;
}

.profile-form .form-group {
  margin-bottom: 20px;
}

.profile-form .form-control {
  border-radius: 8px; /* Bordures plus arrondies pour les champs de saisie */
  padding: 12px;
  border: 1px solid #ced4da; /* Bordure légèrement grise */
  transition: all 0.3s ease; /* Transition douce sur le focus */
}

.profile-form .form-control:focus {
  border-color: #007bff; /* Couleur de bordure au focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Légère ombre bleue au focus */
}

.profile-form .btn-primary {
  width: 100%;
  padding: 12px;
  font-size: 1.1em;
  border-radius: 8px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Ajout d'une transition pour le bouton */
}

.profile-form .btn-primary:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Légère élévation au survol */
}

.profile-form label {
  font-weight: bold;
  color: #495057; /* Couleur de texte adoucie */
  margin-bottom: 5px;
  display: block;
}

.profile-form select[multiple] {
  height: auto;
}

.profile-form select,
.profile-form input[type="text"],
.profile-form input[type="date"] {
  width: 100%;
}

.profile-form button[type="submit"] {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.profile-form button[type="submit"]:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.profile-picture-preview {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.profile-picture-preview img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #007bff; /* Bordure autour de la photo pour la mettre en valeur */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Ombre douce pour l'effet de carte */
}