.report-abuse-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .report-abuse-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .report-abuse-modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .report-abuse-modal button {
    margin-left: 10px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .report-abuse-modal button:first-of-type {
    background-color: #e74c3c;
    color: white;
  }
  
  .report-abuse-modal button:first-of-type:hover {
    background-color: #c0392b;
  }
  
  .report-abuse-modal button:last-of-type {
    background-color: #3498db;
    color: white;
  }
  
  .report-abuse-modal button:last-of-type:hover {
    background-color: #2980b9;
  }