/* Importer les polices Lovelo Line */
@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

/* Style pour le titre principal de la page de profil */
.profile-title-container h1 {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem;
  color: #2F732F;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
}

/* Style global de la page de profil */
.profile-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: transparent;
}

/* Disposition des sections de profil */
.profile-sections-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.profile-sections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.profile-section {
  background-color: #007bff;
  color: #fff;
  border-radius: 12px;
  padding: 30px 20px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-section:hover,
.profile-section:focus {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  background-color: #0056b3;
  outline: none;
}

.profile-section h2 {
  margin-top: 15px;
  font-size: 1.2rem;
  text-decoration: none;
}

.profile-section .fa-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

/* Style pour les notifications */
.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px 6px;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Adaptation pour les écrans plus petits */
@media (max-width: 700px) {
  .profile-sections {
    grid-template-columns: 1fr;
  }

  .profile-section {
    padding: 20px;
  }

  .profile-section h2 {
    font-size: 1.2rem;
  }

  .profile-section .fa-icon {
    font-size: 2rem;
  }

  .notification-count {
    top: 5px;
    right: 5px;
  }
}

/* Conteneur pour le message de développement en bas de la page de profil */
.profile-footer-container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  background-color: transparent; /* Pour laisser apparaître l'arrière-plan de l'app */
}

/* Styles pour le message de développement */
.profile-development-note {
  max-width: 600px;
  padding: 10px;
  background-color: #f0f4f8;
  border: 1px solid #d1e0e5;
  border-radius: 8px;
  text-align: center;
}

.profile-development-message {
  font-size: 16px;
  margin-bottom: 5px;
}

/* Adaptation supplémentaire pour les très petits écrans */
@media (max-width: 400px) {
  .profile-page-container {
    padding: 15px;
  }

  .profile-section {
    padding: 15px;
  }

  .profile-section h2 {
    font-size: 0.9rem;
  }

  .profile-section .fa-icon {
    font-size: 1.8rem;
  }

  .notification-count {
    width: 18px;
    height: 18px;
    font-size: 10px;
  }

  .profile-footer-container {
    margin-top: 15px;
    padding: 10px;
  }

  .profile-development-note {
    padding: 8px;
    font-size: 14px;
  }
}