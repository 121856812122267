/* src/Pages/TermsAcceptance.css */

.terms-acceptance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: #FAF4F5;
}

.read-instruction {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
}

.terms-section {
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-content {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px; /* Ajouter un padding pour éviter le chevauchement de la barre de défilement */
  text-align: left;
}

/* Styles de la barre de défilement pour les navigateurs Webkit */
.terms-content::-webkit-scrollbar {
  width: 8px;
}

.terms-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.terms-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style pour les titres h3 */
.terms-section h3 {
  font-size: 1.5rem;
  color: #0056b3;
  margin-bottom: 15px; /* Marge inférieure pour espacer le texte */
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.terms-acceptance-container button {
  width: 200px;
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.terms-acceptance-container button:hover {
  background-color: #0056b3;
}

.terms-acceptance-container button.disabled {
  background-color: #bbb;
  cursor: not-allowed;
}