/* src/Pages/HomePage.css */

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligner les éléments en haut de la page */
  min-height: 100vh;
  text-align: center;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  max-width: 200px; /* Ajustez cette valeur selon vos besoins */
  height: auto;
  margin-top: 100px;
}

/* Styles communs pour tous les boutons */
.home-page button {
  width: 200px; /* Largeur des boutons */
  padding: 10px;
  font-size: 1em;
  margin: 10px 0; /* Ajouter de la marge au-dessus et au-dessous des boutons */
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition douce pour le hover */
}

/* Style pour le bouton "Sign in with Google" */
.home-page .btn-google {
  background-color: #007bff; /* Rouge de Google */
}

.home-page .btn-google:hover {
  background-color: #0056b3; /* Rouge plus foncé pour le hover */
}

/* Style pour le bouton "Login with Email" */
.home-page .btn-secondary {
  background-color: #007bff; /* Bleu */
}

.home-page .btn-secondary:hover {
  background-color: #0056b3; /* Bleu plus foncé pour le hover */
}

/* Style pour le bouton "Register" */
.home-page .btn-primary {
  background-color: #28a745; /* Vert pour l'inscription */
}

.home-page .btn-primary:hover {
  background-color: #218838; /* Vert plus foncé pour le hover */
}