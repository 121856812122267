/* src/components/AccountPage.css */
.account-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .account-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .account-page .form-group {
    margin-bottom: 15px;
  }
  
  .account-page .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .account-page .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .account-page .btn-primary {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .account-page .btn-primary:hover {
    background-color: #0056b3;
  }