/* src/pages/CountryCityListPage.css */

/* Importer les polices Lovelo Line */
@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

/* Style spécifique pour le sous-titre de la page CountryListPage */
.country-list-page-subtitle {
    font-family: 'Lovelo Line Bold', sans-serif;
    font-size: 3rem;
    color: #2F732F;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    padding-top: 30px;
}