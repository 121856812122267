/* src/Pages/FriendsPage.css */

.friends-page {
  text-align: center;
  padding: 20px;
  background: #FAF4F5;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

/* Style pour le titre principal H1 */
.friends-page h1 {
  font-family: 'Lovelo Line Bold', sans-serif;
  color: #2F732F; /* Couleur de texte attrayante */
  margin-bottom: 30px;
  margin-top: 50px;
  font-size: 3rem; /* Taille de police similaire au style settings-title */
  font-weight: 700;
  text-transform: uppercase; /* Texte en majuscules pour plus d'impact */
  letter-spacing: 1.5px; /* Ajoute un espacement entre les lettres */
}

.friends-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.friends-section {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.friends-section h2 {
  margin-bottom: 20px;
  color: #34495e;
  font-size: 1.5rem;
}

.friends-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.friends-section ul li {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.friends-section ul li:hover {
  background: #f0f0f0;
}

.friends-section ul li .profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.friends-section ul li .friend-actions {
  display: flex;
  align-items: center;
}

.friends-section ul li .action-button {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.friends-section ul li .action-button:hover {
  background-color: #1f639a;
  transform: translateY(-2px);
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.delete-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.delete-button:active {
  background-color: #a93226;
  transform: translateY(1px);
}

.friends-section ul li .remove-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.friends-section ul li .remove-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.friends-section ul li .remove-button:active {
  background-color: #a93226;
  transform: translateY(1px);
}

.friends-section ul li .accept-button {
  background-color: #2ecc71;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.friends-section ul li .accept-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
}

.friends-section ul li .accept-button:active {
  background-color: #1e8449;
  transform: translateY(1px);
}

.friends-section ul li .reject-button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.friends-section ul li .reject-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.friends-section ul li .reject-button:active {
  background-color: #a93226;
  transform: translateY(1px);
}

.friends-section ul li .remove-button:hover,
.friends-section ul li .accept-button:hover,
.friends-section ul li .reject-button:hover {
  filter: brightness(0.9);
}

.notifications-section {
  margin-top: 20px;
}

.notifications-section h3 {
  margin-bottom: 10px;
  color: #34495e;
  font-size: 1.5rem;
}

.notifications-section ul {
  list-style-type: none;
  padding: 0;
}

.notifications-section ul li {
  background: #f1f1f1;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-section ul li p {
  flex: 1;
  margin: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: #2c3e50;
}

.notifications-section button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.notifications-section button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.friends-section ul li .pending-button {
  background-color: #f39c12; /* Une couleur orange pour indiquer une action en attente */
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: not-allowed; /* Curseur qui indique que le bouton n'est pas cliquable */
  margin-left: 10px;
  opacity: 0.8; /* Opacité réduite pour montrer que le bouton est désactivé */
  transition: opacity 0.3s ease;
}

.friends-section ul li .pending-button:hover {
  opacity: 1;
}