.chat-room {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px); /* Ajuste la hauteur pour tenir compte du footer */
  padding-bottom: 30px; /* Espace supplémentaire pour éviter que le bouton de retour soit rogné */
  padding-top: 25px;
  overflow-y: auto; /* Active le scroll */
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2F732F;
  color: white;
  border-bottom: 2px solid #ffffff;
}

.chat-header-content {
  display: flex;
  align-items: center;
}

.chat-header-user-info h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.chat-header-user-info {
  display: flex;
  align-items: center;
}

.chat-header-profile-pic {
  width: 40px;
  height: 40px;
  object-fit: cover; /* Conserve les proportions sans déformation */
  border-radius: 50%; /* Cercle parfait */
  border: 2px solid #fff; /* Bordure blanche */
  margin-right: 10px;
}

.chat-header-user-info h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.flag-button {
  background-color: #ff4c4c;  /* Couleur de fond visible */
  border: none;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 100; /* Priorité supérieure */
  position: relative; /* Assure que le bouton reste bien visible */
}

.flag-button:hover {
  background-color: #ff9999; /* Couleur au survol */
}


.messages {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: 300px !important; /* Force la hauteur */
  max-height: 480px;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  width: fit-content;
  max-width: 80%;
}

.sent {
  background-color: #d1e7fd; /* Couleur des messages envoyés */
  align-self: flex-end; /* Aligner à droite */
}

.received {
  background-color: #e1ffc7; /* Couleur des messages reçus */
  align-self: flex-start; /* Aligner à gauche */
}

.chat-input {
  display: flex;
  width: 100%;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.chat-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.report-button {
  background-color: #ff4d4f; /* Rouge doux pour attirer l'attention */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
  margin-bottom: 10px; /* Espace sous le bouton */
}

.report-button:hover {
  background-color: #d9363e; /* Couleur plus foncée au survol */
  transform: translateY(-2px); /* Léger déplacement vers le haut */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre */
}

.report-abuse-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
  margin: 20px;
}

.report-abuse-modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.report-abuse-modal label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
  font-weight: 600;
}

.report-abuse-modal select,
.report-abuse-modal textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 15px;
  transition: border-color 0.2s;
}

.report-abuse-modal select:focus,
.report-abuse-modal textarea:focus {
  border-color: #007bff; /* Couleur de mise en évidence sur le focus */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2); /* Légère ombre bleue au focus */
}

.report-abuse-modal textarea {
  resize: vertical;
  min-height: 80px;
  font-family: 'Arial', sans-serif;
}

.chat-room .report-abuse-modal button:first-of-type {
  background-color: #e74c3c;
  color: white;
}

.chat-room .report-abuse-modal button:first-of-type:hover {
  background-color: #c0392b;
}

.chat-room .report-abuse-modal button:last-of-type {
  background-color: #3498db;
  color: white;
}

.chat-room .report-abuse-modal button:last-of-type:hover {
  background-color: #2980b9;
}