/* src/Pages/IdentityVerificationPage.css */
.identity-verification-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
  
  .identity-verification-page h2 {
    margin-bottom: 20px;
  }
  
  .identity-verification-page .form-group {
    margin-bottom: 15px;
  }
  
  .identity-verification-page .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
  }
  
  .identity-verification-page .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 1em;
  }