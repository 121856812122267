/* src/Pages/LegalMentions.css */

/* Container principal pour le texte */
.legal-mentions-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Style pour les sous-titres */
.legal-mentions-container h3 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #0056b3;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  text-align: center;
}

/* Style pour les paragraphes */
.legal-mentions-container p {
  margin-bottom: 15px;
  text-align: justify;
}

/* Style pour les listes */
.legal-mentions-container ul {
  margin: 15px 0;
  padding-left: 20px;
}

.legal-mentions-container ul li {
  margin-bottom: 8px;
}

/* Style pour les liens */
.legal-mentions-container a {
  color: #007bff;
  text-decoration: underline;
}

.legal-mentions-container a:hover {
  color: #0056b3;
}

/* Style pour les petits écrans */
@media (max-width: 600px) {
  .legal-mentions-container {
    padding: 20px;
    margin: 20px;
  }

  .legal-mentions-container h3 {
    font-size: 1.3rem;
  }
}