/* Conteneur principal pour la page d'inscription */
.sign-up-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: #FAF4F5; /* Couleur de fond douce */
}

/* Style pour les boutons d'inscription */
.sign-up-email-container button {
  width: 200px; /* Largeur des boutons d'inscription */
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
  border: none;
  background-color: #007bff; /* Utiliser la couleur bleue */
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-up-email-container button:hover {
  background-color: #0056b3; /* Couleur bleue plus foncée pour le hover */
}

/* Style pour les champs de saisie */
.sign-up-email-container input {
  width: 200px; /* Largeur des champs de saisie */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.sign-up-email-container input:focus {
  border-color: #007bff; /* Couleur de la bordure au focus */
  outline: none; /* Supprimer l'outline par défaut */
}

/* Style pour les messages d'erreur */
.sign-up-email-container .error-message {
  color: red;
  font-size: 0.9em;
  margin-bottom: 10px;
}

/* Style pour l'indicateur de force du mot de passe */
.password-strength-bar {
  width: 200px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden; /* Masquer tout dépassement */
}

.password-strength-bar div {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.strength-0 {
  width: 0;
  background-color: #e0e0e0;
}

.strength-1 {
  width: 25%;
  background-color: red;
}

.strength-2 {
  width: 50%;
  background-color: orange;
}

.strength-3 {
  width: 75%;
  background-color: yellow;
}

.strength-4 {
  width: 100%;
  background-color: green;
}

/* Style pour le conteneur des champs de mot de passe */
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 80%; /* Adapter la largeur à l'écran */
  margin-bottom: 10px;
}

/* Style pour le bouton de bascule du mot de passe */
.toggle-password-visibility {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 0.75em; /* Réduire la taille de la police */
  padding: 2px 5px; /* Réduire le padding */
}

.toggle-password-visibility:hover {
  color: #0056b3;
}

/* Media queries pour les petits écrans */
@media (max-width: 600px) {
  .sign-up-email-container input,
  .sign-up-email-container button {
    width: 80%; /* Adapter la largeur à l'écran */
  }
}