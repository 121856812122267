/* src/components/WelcomeMessage.css */
.welcome-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .welcome-message-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh; /* Limiter la hauteur pour permettre le défilement */
    overflow-y: auto; /* Ajouter une barre de défilement verticale */
    text-align: left; /* Alignement à gauche */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .welcome-message-container h2 {
    margin-bottom: 10px;
    text-align: center; /* Alignement au centre pour le titre */
  }
  
  .welcome-tips h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .welcome-tips p {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 16px;
  }
  
  .welcome-tips span {
    margin-right: 8px;
  }
  
  .welcome-message-close-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .welcome-message-close-button:hover {
    background-color: #45a049;
  }

  .welcome-note {
    margin-top: 20px; /* Ajustez cet espace si nécessaire */
    text-align: center;
  }
  
  .development-message {
    font-size: 18px; /* Taille de police ajustée pour mettre en valeur */
    margin-bottom: 10px; /* Espace en bas pour un meilleur espacement */
    display: block;
    text-align: center;
  }