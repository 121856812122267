/* Conteneur principal */
.chat-room-groups {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Remplissage de l'écran */
  padding: 20px;
  background-color: #f4f5f7; /* Arrière-plan doux pour un look moderne */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

/* Barre d'en-tête avec le titre du groupe et les boutons */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.group-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.header-buttons {
  display: flex;
  gap: 10px; /* Espace entre les boutons */
}

.favorite-button,
.report-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.favorite-button:hover,
.report-button:hover {
  background-color: #e9ecef;
}

.report-button {
  color: #ff5c5c;
  border-color: #ff5c5c;
}

.report-button:hover {
  background-color: #ffe5e5;
  color: #ff0000;
}

/* Section des messages */
.chat-room-groups .messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Style des messages */
.chat-room-groups .message {
  padding: 12px 18px;
  margin: 8px 0;
  border-radius: 20px;
  max-width: 80%;
  font-size: 0.95rem;
  line-height: 1.4;
  position: relative;
  word-wrap: break-word;
}

/* Message envoyé */
.chat-room-groups .sent {
  background-color: #007bff;
  color: #ffffff;
  align-self: flex-end;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

/* Message reçu */
.chat-room-groups .received {
  background-color: #e9ecef;
  color: #333;
  align-self: flex-start;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Info de l'expéditeur et heure du message */
.sender-info {
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.message-info {
  font-size: 0.75rem;
  color: #6c757d;
  margin-top: 6px;
}

/* Barre d'entrée de message */
.chat-room-groups .chat-input {
  display: flex;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
}

/* Champ de texte */
.chat-room-groups .chat-input input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 30px;
  font-size: 1rem;
  outline: none;
  margin-right: 10px;
  transition: border-color 0.3s ease;
}

.chat-room-groups .chat-input input:focus {
  border-color: #007bff;
}

/* Bouton d'envoi */
.chat-room-groups .chat-input button {
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.chat-room-groups .chat-input button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

/* Barre de recherche */
.search-bar {
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  margin-right: 10px;
  background-color: #f1f3f5;
  transition: background-color 0.3s ease;
}

.search-bar:focus {
  background-color: #ffffff;
  border-color: #007bff;
  outline: none;
}

/* Boutons de recherche */
button.search-navigation {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.search-navigation:hover {
  background-color: #0056b3;
}

/* Messages mis en surbrillance */
.highlighted {
  background-color: #fff3cd;
}

/* Bouton de signalement */
.small-report-button {
  background-color: transparent;
  border: none;
  color: #ff5c5c;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.small-report-button:hover {
  background-color: #ffe5e5;
  color: #ff0000;
}