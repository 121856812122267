@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

.page-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Ajustez la taille de la police si nécessaire */
  color: #2F732F; /* Couleur du titre */
  margin: 40px 20px 30px; /* Ajoutez des marges en haut, à gauche et à droite */
  text-align: center; /* Centrer le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
}

.conversation-list {
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 15px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.conversation-list-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.conversation-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.conversation-list-item:hover {
  background-color: #e6f5e6;
  transform: translateY(-2px);
}

.conversation-list-link {
  text-decoration: none;
  color: #2F732F;
  font-weight: 600;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.unread-indicator {
  background-color: #ff3b30;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.delete-button {
  background: none;
  border: none;
  color: #ff3b30;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.delete-button:hover {
  color: #cc2a23;
}

.delete-button:focus {
  outline: none;
}

.conversation-list-item::before {
  content: "💬";
  margin-right: 15px;
  font-size: 20px;
  color: #2F732F;
}