.footer-item {
  color: white;
  text-align: center;
  flex: 1;
  position: relative;
  margin-bottom: 15px; /* Réduit la marge en bas */
}

.footer-item a {
  color: white;
  text-decoration: none;
}

.footer-item i {
  font-size: 18px; /* Agrandir légèrement l'icône */
  display: block;
}

.footer-item span {
  display: block;
  font-size: 13px; /* Augmente légèrement la taille du texte */
  margin-top: 3px; /* Réduit l'espace entre l'icône et le texte */
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 2px 6px;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px; /* Ajuste légèrement la position de la notification */
  right: 8px;
}

.footer {
  padding: 10px 0; /* Augmente la taille du footer */
  position: relative;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
}