/* Conteneur principal pour la page de connexion avec email */
.login-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
    background-color: #FAF4F5; /* Couleur de fond douce */
  }
  
  /* Style pour les champs de saisie */
  .login-email-container input {
    width: 300px; /* Largeur des champs de saisie */
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    transition: border-color 0.3s ease;
    box-sizing: border-box; /* Assure que le padding est inclus dans la largeur totale */
  }
  
  /* Style pour les champs de saisie au focus */
  .login-email-container input:focus {
    border-color: #007bff; /* Couleur de la bordure au focus */
    outline: none; /* Supprimer l'outline par défaut */
  }
  
  /* Style pour le bouton de connexion */
  .login-email-container .btn-primary {
    width: 300px; /* Largeur du bouton de connexion */
    padding: 12px;
    font-size: 1em;
    margin: 10px 0; /* Ajouter de la marge autour du bouton */
    border: none;
    background-color: #007bff; /* Couleur bleue */
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Style pour le bouton de connexion au hover */
  .login-email-container .btn-primary:hover {
    background-color: #0056b3; /* Couleur bleue plus foncée pour le hover */
  }
  
  /* Style pour les messages d'erreur */
  .login-email-container .error-message {
    color: red;
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  /* Style du bouton Back */
  .btn-secondary {
    background-color: #ff7e5f; /* Couleur de fond orange vif */
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    border: none; /* Supprime le contour */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Transition douce pour le hover */
    margin-top: 20px; /* Espace au-dessus du bouton */
  }
  
  .btn-secondary:hover {
    background-color: #feb47b; /* Couleur plus claire au survol */
    transform: translateY(-2px); /* Légère élévation au survol */
  }

  .btn-link {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
  .btn-link:hover {
    color: #0056b3;
  }