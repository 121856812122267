/* src/Pages/RatingPage.css */

.rating-page {
    padding: 20px;
    background-color: #FAF4F5; /* Couleur de fond douce pour la page */
    font-family: 'Arial', sans-serif;
  }
  
  @font-face {
    font-family: 'Lovelo Line Bold';
    src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'Lovelo Line Light';
    src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
    font-weight: 300;
  }

  /* Style pour le titre principal H1 */
.rating-page h1 {
    font-family: 'Lovelo Line Bold', sans-serif;
    text-align: center;
    color: #2F732F; /* Couleur de texte attrayante */
    margin-bottom: 40px;
    margin-top: 50px;
    font-size: 3rem; /* Taille de police similaire au style settings-title */
    font-weight: 700;
    text-transform: uppercase; /* Texte en majuscules pour plus d'impact */
    letter-spacing: 1.5px; /* Ajoute un espacement entre les lettres */
  }

.rating-section {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
    transition: box-shadow 0.3s ease;
}

.rating-section:hover {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.rating-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.rating-item:hover {
    background-color: #f1f1f1;
}

.meeting-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.profile-picture {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 15px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.meeting-info h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.meeting-info p {
    font-size: 16px;
    color: #555;
}

.rating-component-wrapper {
    width: 100%;
    max-width: 500px;
}