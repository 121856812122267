/* src/Components/RatingComponent.css */

.rating-form {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    margin: 0 auto;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.rating-form:hover {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15); /* Effet de survol */
    transform: translateY(-5px); /* Effet de levée */
}

.rating-field {
    margin-bottom: 25px;
}

.rating-field label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.rating-field input[type="range"] {
    width: 100%;
    accent-color: #007bff; /* Couleur personnalisée pour le curseur */
}

.rating-value {
    margin-left: 15px;
    font-weight: 600;
    color: #007bff; /* Couleur pour correspondre à l'accent de l'input */
}

.submit-button {
    padding: 12px 25px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.3);
}

.submit-button:hover {
    background-color: #218838;
    transform: translateY(-2px); /* Effet de levée */
}