/* Container principal */
.user-profile-public {
  padding: 20px;
  margin-top: 70px; 
  padding-bottom: 100px; 
}

/* Barre de navigation en haut */
.user-profile-public .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Section de profil */
.user-profile-public .profile-section {
  margin-bottom: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: left; /* Assurez-vous que tout le texte est aligné à gauche */
  display: block;
}

/* Titres des sections - centrés */
.user-profile-public .profile-section h2 {
  font-size: 26px;
  color: #007bff;
  margin-bottom: 15px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  text-align: center; /* Centrage des titres */
}

/* Texte des sections */
.user-profile-public .profile-section p {
  font-size: 18px;
  color: #495057;
  margin-bottom: 15px;
  line-height: 1.7;
  text-align: left; /* Alignement du texte à gauche */
  margin-left: 0;
}

/* Assurer l'alignement correct pour les icônes et le texte */
.user-profile-public .profile-text {
  display: block;
  text-align: left;
}

/* Boutons de navigation */
.user-profile-public .back-button,
.user-profile-public .back-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  border: 2px;
  border-radius: 5px;
  padding: 8px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.user-profile-public .back-button:hover,
.user-profile-public .back-link:hover {
  background-color: #007bff;
  color: white;
}

/* Boutons d'action */
.user-profile-public .connect-button,
.user-profile-public .message-button,
.user-profile-public .unfriend-button,
.user-profile-public .accept-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Icônes et textes alignés à gauche */
.user-profile-public .profile-section p,
.user-profile-public .profile-section .fa-icon {
  text-align: left;
  margin-left: 0;
}

/* Nom et statut de l'utilisateur - centrés */
.user-profile-public h1,
.user-profile-public .status-indicator {
  text-align: center; /* Centrage du nom et du statut */
  margin-bottom: 10px;
}

/* Photo de profil de l'utilisateur */
.user-profile-public .user-profile-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
  margin: 0 auto 10px;
  border: 3px solid #007bff;
}

/* Badge de feedback */
.feedback-badge {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

/* Badge de meetpoints */
.meetpoints-badge {
  color: #ffc107;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Centrer le contenu du badge */
  align-items: center;
  margin-bottom: 10px;
}

.star-icon {
  margin-right: 5px;
  font-size: 24px;
}

.meetpoints-number {
  font-family: 'Courier New', Courier, monospace;
  font-size: 24px;
  margin-right: 5px;
}

/* Indicateur de statut */
.status-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Centrer l'indicateur de statut */
  margin-bottom: 10px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot.online {
  background-color: green;
}

.status-dot.offline {
  background-color: red;
}

.status-text {
  font-size: 14px;
  color: #555;
  text-align: center;
}

.user-profile-public .back-link {
  display: block; /* Afficher le bouton comme un bloc pour permettre le centrage */
  margin: 20px auto; /* Marges automatiques pour centrer horizontalement */
  text-align: center; /* Centrer le texte à l'intérieur du bouton */
  width: fit-content; /* Adapter la largeur au contenu du bouton */
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #007bff; /* Ajout d'une bordure bleue pour correspondre au style de la page */
  border-radius: 5px;
  padding: 8px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.user-profile-public .back-link:hover {
  background-color: #007bff; /* Changement de couleur de fond au survol */
  color: white; /* Couleur du texte blanche au survol */
}

.pending-button {
  background: linear-gradient(135deg, #f0f0f0, #e6e6e6); /* Dégradé moderne */
  color: #808080; /* Couleur du texte gris plus foncé pour contraste */
  border: 1px solid #cccccc; /* Bordure fine */
  border-radius: 8px; /* Coins arrondis pour un look moderne */
  padding: 12px 24px; /* Taille du bouton */
  font-size: 10px; /* Taille de police appropriée */
  font-weight: 600; /* Texte en gras pour une meilleure visibilité */
  text-transform: uppercase; /* Texte en majuscule pour un look élégant */
  cursor: not-allowed; /* Curseur indiquant que le bouton est désactivé */
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1), 2px 2px 5px rgba(255, 255, 255, 0.4); /* Effet d'ombre 3D subtile */
  transition: all 0.3s ease; /* Transition douce pour tout changement de style */
  text-align: center;
  letter-spacing: 1px; /* Espacement entre les lettres pour un look aéré */
}

.pending-button:disabled {
  background: linear-gradient(135deg, #e0e0e0, #d5d5d5); /* Changement de dégradé lorsqu'il est désactivé */
  color: #a0a0a0; /* Couleur de texte plus atténuée */
  border-color: #b0b0b0; /* Bordure plus douce */
}