/* src/App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FAF4F5;
  padding-bottom: 100px; /* Hauteur du footer */
  box-sizing: border-box;
}


.main-content {
  flex: 1; /* Prend tout l'espace vertical disponible */
  padding-bottom: 20px; /* Optionnel: Ajoutez un petit padding en bas si nécessaire */
  box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la hauteur totale */
}

header {
  display: none; /* Supprimez l'élément header */
}

.navbar {
  position: fixed;
  top: 0;
  width: 0%;
  z-index: 1000; /* Assurez-vous que la Navbar est au-dessus de la carte */
  background-color: rgba(255, 255, 255, 0); /* Fond de la Navbar */
}

.navbar.bg-transparent {
  background-color: rgba(255, 255, 255, 0); /* Transparent background */
  width: 0%;
}

.navbar.bg-white {
  background-color: rgba(255, 255, 255, 1); /* White background */
  width: 100%;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.logout-button {
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  color: inherit;
  font: inherit;
  outline: inherit;
}

.logout-button:hover,
.logout-button:focus {
  text-decoration: underline;
}

.container {
  margin-top: 20px;
  flex: 1; /* Make container take up remaining space */
}

.card {
  margin-bottom: 20px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
}

.chat-container {
  margin-top: 20px;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.chat-input {
  margin-top: 10px;
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
}

.carousel-item {
  padding: 0px;
  background-color: #FAF4F5;;
  border-radius: 5px;
  margin: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 100px;
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;
}

.footer-wrapper:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px; /* Ajusté pour correspondre à la nouvelle hauteur */
  background-color: #2F732F;
  border-top-left-radius: 100% 30px;
  border-top-right-radius: 100% 30px;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px; /* Augmente la hauteur de 60px à 70px */
  background-color: transparent;
  padding: 10px 0;
  position: relative;
  color: white;
  text-align: center;
  z-index: 1000;
}

.footer .footer-item a {
  color: #ffffff; /* Couleur des liens en blanc */
}

.footer .footer-item a:hover {
  color: #7A7A7A; /* Surbrillance en gris */
}

.profile-form-container {
  padding-bottom: 60px; /* Ajout de padding en bas pour éviter le chevauchement avec le footer */
  margin-top: 50px;
}

.accueil-page .user-item p {
  text-decoration: none !important;
}