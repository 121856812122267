/* src/Pages/SettingsPage.css */

/* Styles généraux pour la page de paramètres */
.settings-container {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(135deg, #f5f7fa, #e4e9f0);
  border-radius: 15px;
  max-width: 900px;
  margin: 50px auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  padding-bottom: 60px;
  margin-top: 50px;
  overflow: hidden;
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

.settings-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Ajustez la taille de la police si nécessaire */
  color: #2F732F;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 700;
  text-transform: uppercase; /* Ajoute un style en majuscules pour le titre */
  letter-spacing: 1.5px; /* Ajoute un espacement entre les lettres */
}

/* Navigation des onglets */
.settings-nav {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}

.settings-link {
  text-decoration: none;
  color: #333;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: 600;
  transition: color 0.3s, background-color 0.3s;
  position: relative;
  z-index: 2;
}

.settings-link.active {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.settings-link:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Conteneur des actions de réglages */
.settings-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

/* Boutons d'actions */
.action-button {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 10px 0; /* Ajout de marges pour espacement vertical */
  max-width: 250px;
  width: calc(100% - 30px); /* Garde un bon aspect sur des petits écrans */
}

.logout-button {
  background-color: #d9534f;
}

.logout-button:hover {
  background-color: #c9302c;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(201, 48, 44, 0.2);
}

.deactivate-button {
  background-color: #4a90e2;
}

.deactivate-button:hover {
  background-color: #357ABD;
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(53, 122, 189, 0.2);
}

.delete-button {
  background-color: #d0021b;
}

.delete-button:hover {
  background-color: #a00116;
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(160, 1, 22, 0.2);
}

/* Effet de transition pour le contenu */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Styles Responsives */
@media (max-width: 700px) {
  .settings-container {
      padding: 20px;
      padding-bottom: 30px;
      background: #ffffff;
      border: 1px solid #e9ecef;
      border-radius: 10px;
      max-width: 600px;
      margin: 0 auto;
      box-sizing: border-box;
      margin-top: 40px;
  }

  .settings-nav {
      flex-direction: column;
      gap: 15px;
  }

  .settings-actions {
      width: 100%;
  }

  .action-button {
      width: 90%;
      padding: 12px;
  }
}