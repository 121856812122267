/* Préfixer toutes les classes avec .map-container pour éviter les conflits globaux */
.map-container {
  position: relative; /* Assurez-vous que le conteneur de la carte a une position relative */
  z-index: 1; /* Définit le z-index inférieur à celui du carrousel */
  padding-bottom: 0px; /* Ajoutez de l'espace pour séparer la carte et le carrousel */
}

.map-container .leaflet-container {
  height: 40vh;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1; /* Assurez que la carte a un z-index inférieur à celui du carrousel */
}

.map-container .nearby-users-carousel {
  position: relative; /* Position relative pour gérer correctement le défilement */
  z-index: 2; /* Z-index supérieur à celui de la carte */
  overflow: hidden;
  background-color: #FAF4F5; /* Optionnel : ajoute un fond pour le carrousel */
}

.map-container .nearby-users-carousel .carousel-item {
  height: calc(100% - 40px); /* Ajuste la hauteur pour être égale à .user-item avec 20px de marge haute et basse */
  padding: 0px;
  box-sizing: border-box;
  background-color: #FAF4F5;
  overflow-y: hidden; /* Désactive le défilement dans le carrousel */
}


.map-container .user-item {
  width: 65%;
  text-align: center;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative; /* Pour positionner les badges correctement */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.map-container .user-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.map-container .user-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 3px solid #007bff;
}

.map-container .user-item h5 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.map-container .user-item p {
  margin: 5px 0;
  font-size: 15px;
  color: #666;
}

.map-container .user-item p strong {
  color: #007bff;
}

.map-container footer {
  position: relative;
  z-index: 10;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent; /* Assure que le fond reste transparent */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: transparent; /* Empêche tout changement de couleur au survol */
}

/* Badge pour les feedbacks positifs */
.badge-primary {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  margin-top: 5px;
  display: inline-block;
}

.status-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  align-items: center;
}

.status-dot.online {
  background-color: green;
  align-items: center;
}

.status-dot.offline {
  background-color: red;
}

.status-text {
  font-size: 0.8rem;
  color: #555;
}

.feedback-badge {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.map-container .meetpoints-badge {
  color: #ffc107;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
}

.map-container .meetpoints-label {
  margin-left: 5px;
}

.map-container .star-icon {
  margin-right: 5px;
  font-size: 16px;
}

.map-container .meetpoints-number {
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  margin-right: 5px;
}

.user-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.badges-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 10px;
  right: 20px;
}

.feedback-badge,
.meetpoints-badge,
.status-indicator {
  margin-bottom: 5px;
  align-items: center;
}

.user-profile-image {
  margin-top: 80px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.add-marker-button,
.reset-location-button {
  position: absolute;
  top: 35px;
  right: 10px;
  margin: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.add-marker-button:hover,
.reset-location-button:hover {
  background-color: #0056b3;
}

/* Style spécifique pour chaque bouton */
.add-marker-button {
  top: 70px;
}

/* Media queries pour améliorer l'affichage sur les écrans mobiles */
@media (max-width: 600px) {
  .badges-container {
    position: relative;
    top: 0;
    right: 0;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
  }

  .user-item-container {
    align-items: center;
  }

  .user-profile-image {
    margin-top: 10px;
  }

  .map-container .nearby-users-carousel .carousel-item {
    max-height: 60vh;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 20px;
    height: 20px;
  }

  .status-indicator {
    position: static;
    margin-bottom: 5px;
    align-items: center;
  }

  .feedback-badge,
  .meetpoints-badge {
    position: static;
    margin: 0 auto 5px auto;
    align-items: center;
  }

  .map-container .user-profile-image {
    margin-top: 20px;
  }

  /* Ajustement de la largeur et de l'alignement pour les petits écrans */
  .map-container .user-item {
    width: 90%;
    text-align: center;
  }

  .user-marker-icon {
    border-radius: 50%; /* Bulle circulaire */
    border: 2px solid white; /* Bordure blanche pour la bulle */
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Assure que l'image reste dans le cercle */
  }
  
  .user-profile-image {
    border-radius: 50%; /* Photos rondes */
    object-fit: cover; /* L'image couvre entièrement le conteneur */
    width: 100%; /* Remplit le cercle */
    height: 100%; /* Remplit le cercle */
  }
}