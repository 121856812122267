.meetings-page {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #FAF4F5; /* Background color for the page */
}


@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

.meetings-page h1 {
  font-family: 'Lovelo Line Bold', sans-serif;
  text-align: center;
  color: #2F732F; /* Couleur de texte attrayante */
  margin-bottom: 40px;
  margin-top: 50px;
  font-size: 3rem; /* Taille de police similaire au style settings-title */
  font-weight: 700;
  text-transform: uppercase; /* Texte en majuscules pour plus d'impact */
  letter-spacing: 1.5px; /* Ajoute un espacement entre les lettres */
}

.friends-section {
  margin-bottom: 30px;
}

.meetings-section {
  margin-top: 20px;
}

.meeting-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.meeting-item:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #00aaff; /* Blue border around the profile picture */
}

.meeting-actions {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.action-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.accept-button {
  background-color: #4caf50;
  color: white;
}

.accept-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.reject-button {
  background-color: #f44336;
  color: white;
}

.reject-button:hover {
  background-color: #e53935; /* Darker red on hover */
}

.initiate-button {
  background-color: #008CBA;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.initiate-button:hover {
  background-color: #007bb5; /* Darker blue on hover */
}

.meetpoints-section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.meetpoints-content {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.meetpoints-icon {
  font-size: 24px;
  color: #ffd700; /* Gold color for the star icon */
}

.meeting-actions .action-button {
  flex: 1;
}