/* src/Pages/FavoriteGroupsPage.css */
.favorite-groups-page {
    padding: 20px;
  }


  @font-face {
    font-family: 'Lovelo Line Bold';
    src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'Lovelo Line Light';
    src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
    font-weight: 300;
  }
  
  .favorite-groups-page h1 {
    font-family: 'Lovelo Line Bold', sans-serif;
    text-align: center;
    color: #2F732F; /* Couleur de texte attrayante */
    margin-bottom: 40px;
    margin-top: 50px;
    font-size: 3rem; /* Taille de police similaire au style settings-title */
    font-weight: 700;
    text-transform: uppercase; /* Texte en majuscules pour plus d'impact */
    letter-spacing: 1.5px; /* Ajoute un espacement entre les lettres */
  }
  
  .favorite-groups-list {
    list-style-type: none;
    padding: 0;
  }
  
  .favorite-group-button {
    background: none;
    border: none;
    color: #007BFF;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    width: 100%;
    display: block;
  }
  
  .favorite-group-button:hover {
    text-decoration: underline;
  }