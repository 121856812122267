.country-city-list-container {
  display: flex;
  flex-direction: column;
  min-height: auto; /* Assure que le conteneur prend la hauteur de la fenêtre */
  background-color: #FAF4F5;
  padding: 20px;
}

.country-city-list-content {
  flex: 1 1 auto;
  max-width: 1000px;
  margin: 0 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Permet le défilement vertical si le contenu dépasse la hauteur de l'élément */
  max-height: calc(100vh - 100px); /* Ajuste la hauteur maximale pour rester dans l'élément App */
}

.country-selection-container {
  flex: 0 1 auto; /* Permet de s'adapter au contenu */
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Importer les polices Lovelo Line */
@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lovelo Line Light';
  src: url('../fonts/Lovelo_Line_Light.otf') format('opentype');
  font-weight: 300;
}

/* Style pour le titre de la page avec la classe page-title */
.page-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Taille de police pour le H1 */
  color: #2F732F; /* Couleur verte similaire */
  margin-bottom: 40px; /* Marge inférieure pour l'espacement */
  margin-top: 50px;
  font-weight: 700;
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  text-align: center; /* Centre le texte */
}

.chat-room-container {
  flex-grow: 1; /* Permet au conteneur de croître pour utiliser l'espace restant */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Désactive le défilement interne */
  min-height: auto; /* Permet la flexibilité de la hauteur dans un conteneur flex parent */
}

.chat-room-container h3 {
  text-align: center; /* Centre le texte */
  font-size: 1.8rem; /* Ajustez la taille de la police */
  margin-bottom: 20px;
  color: #333; /* Couleur du texte */
  font-weight: 700; /* Gras pour le titre */
  letter-spacing: 1px; /* Espacement entre les lettres pour un effet moderne */
  position: relative; /* Nécessaire pour positionner le bouton "favorites" de manière absolue */
}

h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.country-option {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.country-option:hover {
  background-color: #f1f1f1;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
  vertical-align: middle;
  border-radius: 3px;
}

.select__control {
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.select__control:hover {
  border-color: #007bff;
}

.select__menu {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.select__option--is-focused {
  background-color: #e9ecef;
}

.select__option--is-selected {
  background-color: #007bff;
  color: #fff;
}

.search-results {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.search-results li {
  margin: 0;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ddd;
}

.search-results li:last-child {
  border-bottom: none;
}

.search-results li:hover {
  background-color: #f1f1f1;
}

.search-results button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0;
  font-size: 16px;
  color: #333;
}

.search-results button:hover {
  background-color: #f1f1f1;
}

.chat-controls {
  display: flex;
  justify-content: space-between; /* Aligne "Add to favorites" à gauche et "Report Group" à droite */
  align-items: center; /* Centre verticalement les éléments */
  margin-top: -10px; /* Ajuste la position des boutons par rapport au titre */
}

/* Aligner les boutons individuellement */
.favorite-button {
  margin-right: auto; /* Place le bouton à gauche */
  padding: 8px 16px;
  font-size: 1rem;
  color: #007bff;
  background-color: transparent;
  border: 2px solid #007bff;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.favorite-button.favorited {
  color: #ffc107;
  border-color: #ffc107;
}

.favorite-button:hover {
  background-color: #007bff;
  color: #fff;
}

.favorite-button.favorited:hover {
  background-color: #ffc107;
  color: #fff;
}

.favorite-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.report-button {
  margin-left: auto; /* Place le bouton à droite */
  padding: 8px 16px;
  font-size: 1rem;
  color: #ff5c5c;
  background-color: transparent;
  border: 2px solid #ff5c5c;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.report-button:hover {
  background-color: #ff5c5c;
  color: #fff;
}

.report-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Désactiver et griser les éléments lorsqu'il n'y a pas de groupe sélectionné */
button:disabled,
input:disabled {
  background-color: #e0e0e0;  /* Couleur gris clair */
  color: #a0a0a0;  /* Couleur du texte grisé */
  cursor: not-allowed;  /* Changer le curseur pour indiquer que l'élément est désactivé */
}

.search-navigation:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.search-bar:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
}

.report-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

/* Styles pour le titre du forum */
.forum-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Taille de la police */
  color: #2F732F; /* Couleur du texte */
  margin: 30px 20px; /* Marges autour du titre */
  margin-top: 50px;
  text-align: center; /* Centrer le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  display: inline; /* Permet au texte de s'afficher sur la même ligne */
}

/* Alignement propre du header */
.forum-header {
  text-align: center;
  margin-bottom: 40px; /* Espace sous le titre */
  margin-top: 50px;
}