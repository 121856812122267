/* Accueil.css */

.accueil-page {
  font-family: 'Roboto', sans-serif;
  background-color: #FAF4F5;
  color: #333;
  padding: 20px;
}

.accueil-page .header {
  text-align: center;
  margin-bottom: 40px;
}

.accueil-page h1 {
  font-size: 32px;
  color: #007bff;
}

.accueil-page .main-content {
  max-width: 1200px;
  margin: 0 auto;
}

.accueil-page .nearby-section,
.accueil-page .groups-section,
.accueil-page .advertisements-section {
  margin-bottom: 40px;
}

.accueil-page h3 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.8rem; /* Augmente la taille de la police pour plus de visibilité */
  text-transform: uppercase; /* Mettre en majuscules pour un effet moderne */
  letter-spacing: 1.2px; /* Espacement entre les lettres pour un effet stylisé */
  border-bottom: 2px solid #007bff; /* Ligne inférieure pour souligner le titre */
  padding-bottom: 10px; /* Espace entre le texte et la ligne inférieure */
  display: inline-block; /* Permet de centrer avec text-align */
  text-align: center; /* Centre le texte */
  width: 100%; /* Utilise toute la largeur disponible pour centrer correctement */
}

.accueil-page .user-item,
.accueil-page .group-item {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
}

.accueil-page .user-item:hover,
.accueil-page .group-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}

.accueil-page .user-item h5,
.accueil-page .group-item h5 {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
}

.accueil-page .user-item p,
.accueil-page .group-info p {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.accueil-page .user-profile-image,
.accueil-page .group-item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 3px solid #007bff;
}

.accueil-page .advertisements {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.accueil-page .advertisements p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.accueil-page .footer {
  margin-top: 40px;
  padding: 20px 0;
  background-color: #343a40;
  color: #fff;
  text-align: center;
}

.accueil-page .footer a {
  color: #007bff;
  text-decoration: none;
}

.accueil-page .footer a:hover {
  text-decoration: underline;
}

.group-slider-controls {
  text-align: center;
  margin-top: 20px;
}

.group-slider-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.group-slider-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.accueil-page .group-item {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accueil-page .group-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.accueil-page .group-item h5 {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 600;
}

.accueil-page .group-item p {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.accueil-page .group-item img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.accueil-page .group-info {
  display: flex;
  flex-direction: column;
}

.accueil-page .group-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #007bff;
  margin-right: 15px;
}

.accueil-page .user-item p a {
  text-decoration: none;
  color: inherit;
}

.accueil-page .user-item a {
  text-decoration: none;
  color: inherit;
}

.accueil-page .user-info {
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  align-items: center; /* Centre les éléments verticalement */
}

.accueil-page .user-basic-info {
  text-align: center; /* Centre le texte sous l'image */
  margin-top: 10px; /* Ajoute un peu d'espace entre l'image et les informations de base */
}

.accueil-page .feedback-badge {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin: 5px 0; /* Uniformiser les marges verticales */
  text-align: right;
  z-index: 10; /* Z-index inférieur pour rester sous le modal */
}

.accueil-page .meetpoints-badge {
  color: #ffc107;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0; /* Uniformiser les marges verticales */
  text-align: center;
}

.accueil-page .star-icon {
  margin-right: 5px;
  font-size: 14px;
}

.accueil-page .meetpoints-number {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  margin-right: 5px;
}

.accueil-page .status-indicator {
  display: inline-flex;
  align-items: center;
  margin: 5px 0; /* Uniformiser les marges verticales */
}

.accueil-page .status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.accueil-page .status-dot.online {
  background-color: green;
}

.accueil-page .status-dot.offline {
  background-color: red;
}

.accueil-page .user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 20px;
  margin-left: auto;
  justify-content: flex-start; /* Aligner les éléments en haut de la colonne */
  height: 100%; /* Assure que la hauteur est égale à celle du conteneur parent */
}

.accueil-page .status-text {
  text-align: right;
}


.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f500;
  border-bottom: 2px solid #ddd;
  top: 0;
  z-index: 1000;
}

.header h1 {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Taille de la police du texte "Welcome on Meevel App" */
  color: #2F732F; /* Couleur du texte */
  margin: 30px 20px; /* Marges autour du titre */
  text-align: center; /* Centrer le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  display: inline; /* Permet au texte de s'afficher sur la même ligne */
}

.geolocation-button {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.geolocation-button:hover {
  background-color: #0056b3;
}

.geolocation-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.location-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.city-name {
  font-size: 14px;
  color: #333;
}

.selected-city {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  background-color: #28a745;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.city-icon {
  color: #007bff;
  margin-right: 5px;
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

.welcome-text {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Taille de la police */
  color: #2F732F; /* Couleur du texte */
  margin: 30px 20px; /* Marges autour du titre */
  text-align: center; /* Centrer le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  display: inline; /* Permet au texte de s'afficher sur la même ligne */
}

.user-name {
  font-family: 'Arial', sans-serif; /* Définit une police différente pour le nom de l'utilisateur */
  font-weight: 700; /* Gras pour le nom de l'utilisateur */
  color: #007bff; /* Couleur pour le nom de l'utilisateur */
  text-transform: capitalize; /* Met en majuscules la première lettre de chaque mot */
}

.advertisements-section {
  margin: 20px 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.advertisement-item {
  margin-bottom: 20px;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.advertisement-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.advertisement-image:hover {
  transform: scale(1.05);
}

.advertisement-details {
  margin-top: 10px;
}

.advertisement-details h4 {
  margin: 10px 0;
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
}

.partner-info {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 8px 0;
  color: #333;
}

.advertisement-details p {
  margin-bottom: 8px;
  color: #555;
  font-size: 1rem;
}

.partner-address {
  color: #007bff;
  font-style: italic;
}

.advertisement a {
  color: #007bff;
  text-decoration: none;
}

.advertisement a:hover {
  text-decoration: underline;
}

.partners-ads {
  display: flex;
  flex-direction: column; /* Align vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text and elements */
}

.partner-ad-item {
  margin: 20px 0; /* Add some space between partner items */
  width: 100%; /* Optionally control the width */
  max-width: 600px; /* Set a max width for the partner items */
}

.partner-ad-image {
  max-width: 100%;
  height: auto; /* Ensure the image is responsive */
}

.no-partners-message {
  text-align: center;
  margin-bottom: 10px; /* Ajustez cette valeur pour réduire l'espace */
}

.no-partners-message img {
  margin-top: 5px; /* Ajustez cette valeur pour rapprocher l'image de la phrase */
  max-width: 150px; /* Ajustez la taille du logo si nécessaire */
}