/* src/Pages/PrivacyPolicy.css */

/* Container principal pour le texte */
.privacy-policy-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Style pour les sous-titres */
.privacy-policy-container h3 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #0056b3;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  text-align: center;
}

/* Style pour les paragraphes */
.privacy-policy-container p {
  margin-bottom: 15px;
  text-align: justify;
}

/* Style pour les listes */
.privacy-policy-container ul {
  margin: 15px 0;
  padding-left: 20px;
}

.privacy-policy-container ul li {
  margin-bottom: 8px;
}

/* Style pour les liens */
.privacy-policy-container a {
  color: #007bff;
  text-decoration: underline;
}

.privacy-policy-container a:hover {
  color: #0056b3;
}

/* Style pour les petits écrans */
@media (max-width: 600px) {
  .privacy-policy-container {
    padding: 20px;
    margin: 20px;
  }

  .privacy-policy-container h3 {
    font-size: 1.3rem;
  }
}