.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 60px; /* Ajuster pour éviter que le contenu ne soit masqué par le footer */
}

@font-face {
  font-family: 'Lovelo Line Bold';
  src: url('../fonts/Lovelo_Line_Bold.otf') format('opentype');
  font-weight: bold;
}

.chat-title {
  font-family: 'Lovelo Line Bold', sans-serif;
  font-size: 3rem; /* Ajustez la taille de la police si nécessaire */
  color: #2F732F; /* Couleur pour le titre */
  margin-bottom: 0px;
  margin-top: 50px;
  text-align: center; /* Centre le texte */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
}